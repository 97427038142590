import { genDefaultItem, genSubheaderItem } from '@/utils/list-generators';
import { formatCoordinates } from '@/utils';

export function genAccessListFragment({ readerGroup, userGroup, editorGroup }) {
  return [
    genSubheaderItem('Access'),
    genDefaultItem({
      title: editorGroup,
      icon: '$edit'
    }),
    genDefaultItem({
      title: userGroup,
      icon: '$execute'
    }),
    genDefaultItem({
      title: readerGroup,
      icon: '$view'
    })
  ];
}
