import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import { objectPropertyService } from '@/modules/common/api';
import {
  genMenuItemCopy,
  genMenuItemEdit,
  genMenuItemShowHistory
} from '@/utils/list-generators';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { geotagservice } from '@/modules/geotags/api';
import router from '@/router';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';

export function useGeotagItemMenu() {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { list, getGeotagById } = useGeotags();
  const { getNextRoute } = useHistoryStack();

  return {
    genMenu: (property, objectId) => {
      const { id, value } = property;
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () => import('@/components/popup/PopupValueEdit.vue'),
            props: {
              value,
              select: {
                items: list.value,
                itemText: 'name',
                itemValue: 'id',
                clearable: true
              },
              autocomplete: true,
              onConfirm: async geotagId => {
                if (value !== geotagId) {
                  objectPropertyService.update(id, {
                    value: geotagId
                  });
                  await geotagservice.updateLinkToObject(
                    objectId,
                    value,
                    geotagId
                  );
                }
              }
            }
          });
        }),
        genMenuItemCopy(async () => {
          const val = getGeotagById(value)?.id || '';
          copyWithAlert(val);
        }),
        genMenuItemShowHistory(async () => {
          await router.push(
            getNextRoute({
              component: componentAliases.hec,
              props: {
                bench: 30,
                ids: [id],
                monitoringItem: {
                  id: objectId,
                  type: 'geotagItem'
                }
              }
            })
          );
        })
      ];
    }
  };
}
