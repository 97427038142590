<template>
  <base-list :items="items" />
</template>

<script>
import {
  genSubheaderItem,
  genDefaultItem,
  genMultilineItem,
  hideIfEmpty,
  genServiceNameItem
} from '@/utils/list-generators';
import { computed, inject } from '@vue/composition-api';

import { useCopy } from '@/compositions/copy';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { useGeotagItemMenu } from '@/modules/geotags/compositions/geotagItemMenu';
import { useDefaultItemMenu } from '@/modules/geotags/ui/geotag-card/general/defaultItemMenu';
import { genAccessListFragment } from '@/modules/access/compositions/access-list-fragment';

export default {
  name: 'GeozoneCardGeneral',
  setup() {
    const entity = inject('entity');
    const { copyWithAlert } = useCopy();
    const { getGeotagById } = useGeotags();
    const { genMenu: genGeotagMenu } = useGeotagItemMenu();
    const { genMenu: genAltitudeMenu } = useDefaultItemMenu();

    const items = computed(() => [
      genSubheaderItem('Position'),
      genDefaultItem({
        subTitle: `Ceiling height: ${entity.value?.positionMaxAltitude?.value}`,
        icon: '$max_altitude',
        invert: true,
        actions: genAltitudeMenu(entity.value?.positionMaxAltitude)
      }),
      genDefaultItem({
        subTitle: `Floor height: ${entity.value?.positionMinAltitude?.value}`,
        icon: '$min_altitude',
        invert: true,
        actions: genAltitudeMenu(entity.value?.positionMinAltitude)
      }),
      genDefaultItem({
        subTitle: `Geotag: ${getGeotagById(entity.value.positionGeotagId.value)
          ?.name || 'n/a'}`,
        icon: '$geotag',
        invert: true,
        actions: genGeotagMenu(entity.value.positionGeotagId, entity.value.id)
      }),
      ...hideIfEmpty(entity.value.description, [
        genSubheaderItem('Description'),
        genMultilineItem({
          text: entity.value.description
        })
      ]),
      ...genAccessListFragment({
        readerGroup: `Readers: ${entity.value.userGroupByReadergroup.groupName}`,
        userGroup: `Users: ${entity.value.userGroupByUsergroup.groupName}`,
        editorGroup: `Editors: ${entity.value.userGroupByEditorgroup.groupName}`
      }),
      genSubheaderItem('Service'),
      genDefaultItem(
        {
          icon: '$uuid',
          title: entity.value.id
        },
        {
          click: () => copyWithAlert(entity.value.id)
        }
      ),
      genServiceNameItem(entity.value.name, {
        click: () => copyWithAlert(entity.value.name)
      })
    ]);

    return { items };
  }
};
</script>

<style></style>
